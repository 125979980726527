<template>
    <div class="page">
        <div class="container">
            <div class="auth-box">
                <van-image :src="require('@/assets/images/head.png')" />
                <div class="tips">
                    <div class="cn"></div>
                    <div class="en">
                    </div>
                </div>
                <van-button type="info" round block @click="auth"
                    >微信授权登陆</van-button
                >
            </div>
        </div>
    </div>
</template>

<script>
import { NavBar, Button, Image, Toast } from "vant";
export default {
    components: {
        [NavBar.name]: NavBar,
        [Button.name]: Button,
        [Image.name]: Image,
    },
    data() {
        return {
        };
    },
    mounted() {
        let code = this.$route.query.code;
        console.log(code);
        if (code == undefined || code == "" || code == null) {
            Toast("请授权登录");
        } else {
         localStorage.setItem('code',this.$route.query.code);
         this.$router.push({path:'/'})
        }
    },
    methods: {
        auth() {
            let APPID = "wx3002de747e927b28";
            let REDIRECT_URI = encodeURIComponent("https://report.ifinnet.cn/#login");
            let SCOPE = "snsapi_userinfo";
            location.href = `https://miniapp.ifinnet.cn/get-weixin-code.html?appid=${APPID}&redirect_uri=${REDIRECT_URI}&scope=${SCOPE}&state=STATE `;
        },
    },
};
</script>

<style scoped>
.auth-box {
    padding-top: 60px;
}
.tips {
    text-align: center;
    padding: 30px 0;
}
.tips .cn {
    font-size: 18px;
    margin-bottom: 8px;
    font-weight: bold;
}
.tips .en {
    font-size: 12px;
    color: #666;
}
</style>